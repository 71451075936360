import { MessageProps } from ".";

const NoDegrees = ({ copy }: MessageProps) => {
  return (
    <div className="border-solid border-putty-400 border-2 mt-8">
      <div className="p-10">
        <p className="text-3xl font-bold">
          Unfortunately, {copy ? copy : "these"} programs are not available in
          your location.
        </p>
        <p className="text-base">
          We have other exciting learning opportunities for you. Explore{" "}
          {copy === "masters" || copy === "bachelors"
            ? `some of our most popular subjects`
            : "related programs and courses"}
          .
        </p>
      </div>
    </div>
  );
};

export default NoDegrees;
