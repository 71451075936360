const NoTaxi = () => {
  return (
    <div className="border-solid border-putty-400 border-2 mt-8">
      <div className="p-10">
        <p className="text-3xl font-bold">
          Unfortunately, this degree program is not available in your location.
        </p>
      </div>
    </div>
  );
};

export default NoTaxi;
