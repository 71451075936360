import { ProductSummary } from "../types";
import { validateLocation } from "@/utils/functions/validateLocation";

export function personalizeProductList(
  productData: ProductSummary[],
): ProductSummary[] {
  return productData.filter((product) => {
    const { productType, locationRestrictions, allowedIn, blockedIn } = product;

    // If the product is not a degree or has no location restrictions, include it
    if (
      !["Masters", "Bachelors", "Doctorate", "Certificate"].includes(
        productType,
      )
    ) {
      return true;
    }

    const effectiveAllowedIn =
      allowedIn ?? locationRestrictions?.[0]?.allowedIn ?? [];
    const effectiveBlockedIn =
      blockedIn ?? locationRestrictions?.[0]?.blockedIn ?? [];

    return validateLocation(effectiveAllowedIn, effectiveBlockedIn);
  });
}
