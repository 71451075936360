import ProductCard from "@/components/ProductCard";
import type { ProductSummary } from "@/lib/productSummary/types";
import { FormatSubType } from "@/constants/tagular/main";
import { useLocale } from "next-intl";
import { getTranslatedProductType } from "./utils/getTranslatedProductType";
import { ProgramType } from "@/lib/program/types";

interface ProductCardListProps {
  products: ProductSummary[];
  skipViewEvents?: ProductSummary[];
  shouldScroll?: boolean;
  location?: string;
  cardFormatSubType?: FormatSubType;
  className?: string;
  isMultiCourse?: boolean;
  tagular_variant?: string;
}

function ProductCardList({
  products,
  location,
  cardFormatSubType,
  className,
  shouldScroll = true,
  skipViewEvents = [],
  isMultiCourse = false,
  tagular_variant,
}: ProductCardListProps) {
  const locale = useLocale();
  if (!products || products.length === 0) {
    return null;
  }

  const styles = `gap-4 py-4 ${isMultiCourse ? "flex flex-wrap md:flex-nowrap overflow-x-scroll" : shouldScroll ? "flex overflow-x-scroll" : "dynamic-grid flex-wrap"} ${className}`;
  const hideCount = [
    ProgramType.BACHELORS,
    ProgramType.MASTERS,
    ProgramType.DOCTORATE,
  ];

  return (
    <div className={styles}>
      {products.map((product, index) => {
        product.productType = product.productType ?? "course";
        return (
          <ProductCard
            key={`${location}-${product.productUuid}`}
            name={product.productName}
            href={`/${product.productSlug}`}
            imageUrl={product.productImageUrl}
            type={getTranslatedProductType(product.productType, locale)}
            partnerName={product.partnerKeys}
            partnerLogoUrl={product.partnerLogoUrl}
            location={location}
            index={index}
            subjects={product.attributes}
            formatSubtype={cardFormatSubType}
            skipViewEvent={skipViewEvents?.includes(product)}
            objectId={product.objectId}
            productSource={product.productSource}
            product={product.productCategory}
            uuid={product.productUuid}
            parentTitle={product.productName}
            activeRunKey={product.activeRunKey}
            childCoursesCount={
              hideCount.includes(product.productType as ProgramType)
                ? undefined
                : (product.childCoursesCount ??
                  product.courseCount ??
                  undefined)
            }
            organizationLogoOverride={product.organizationLogoOverride}
            tagular_variant={tagular_variant}
          />
        );
      })}
    </div>
  );
}

export default ProductCardList;
