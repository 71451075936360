import React, { ReactNode } from "react";
import NoDegrees from "./NoDegrees";
import NoTaxi from "./NoTaxiForm";

export interface MessageProps {
  variant?: "no-degree" | "no-taxi";
  copy?: string;
}

export default function Message({ variant, copy }: MessageProps): ReactNode {
  switch (variant) {
    case "no-degree":
      return <NoDegrees copy={copy} />;
    case "no-taxi":
      return <NoTaxi />;
    default:
      return <NoDegrees copy={copy} />;
  }
}
