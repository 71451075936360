"use client";
import { useLocale } from "next-intl";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import CTALink from "@/components/CTA/ctaLink";
import ProductCardList from "@/components/ProductCardList";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
  FormatSubType,
} from "@/constants/tagular/main";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import { cn } from "@/lib/utils";
import { makeSafeAttributeString } from "@/utils/functions/getIdLabel";
import { TagularVariant } from "@/utils/tagular/dataTranslationMatrices";
import type { ProductCardListByUUIDClientProps } from "./types";
import { hyphenateForTagular } from "@/utils/tagular/helpers";
import { personalizeProductList } from "@/lib/productSummary/helper/personalizeProductList";
import Message from "@/components/ContentBlock/variants/Message";

export default function ProductCardListByUUIDClient({
  title = "",
  cardFormatSubtype = FormatSubType.Grid,
  cardWrapperClassName = "",
  contractText = "",
  ctaLabel = "",
  ctaUrl = "",
  className = "",
  expandText = "",
  initialCardsLength = 4,
  isProfEdPage = false,
  isMultiCourse = false,
  location = "",
  products,
  productType = "",
  showAllProducts = false,
  fallbackProducts = [],
  copy,
  showDisclaimer,
  children,
}: ProductCardListByUUIDClientProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const locale = useLocale();

  const expandLabel = expandText || (locale === "es" ? "Ver más" : "Show More");
  const contractLabel =
    contractText || (locale === "es" ? "Ver menos" : "Show Less");

  const tagular_variant = isProfEdPage
    ? TagularVariant.ProfessionalCertificate
    : undefined;

  const webElement: ElementClicked["webElement"] = {
    location,
    position: "1",
    elementType: ElementType.Link,
    htmlId: "show-more-link",
    text: isExpanded ? "Show Less" : "Show More",
    name: isExpanded
      ? hyphenateForTagular("Show Less")
      : hyphenateForTagular("Show More"),
  };

  const { elementClicked, elementViewed } = useElementEvents({
    webElement,
  });
  const { ref: showMoreRef, inView: showMoreInView } = useInView(
    DEFAULT_USE_IN_VIEW_OPTIONS,
  );

  useEffect(() => {
    if (showMoreInView) {
      elementViewed();
    }
  }, [showMoreInView, elementViewed]);

  const isUsingFallback = personalizeProductList(products).length === 0;

  const filteredProducts = isUsingFallback
    ? fallbackProducts
    : personalizeProductList(products);

  const initialProducts = useMemo(
    () => filteredProducts?.slice(0, initialCardsLength),
    [filteredProducts, initialCardsLength],
  );

  if (!filteredProducts || filteredProducts.length === 0) {
    return null;
  }

  const titleId = makeSafeAttributeString(title);

  const displayedProducts =
    isExpanded || showAllProducts ? filteredProducts : initialProducts;
  const skipEvents = isExpanded ? initialProducts : [];

  const handleShowButtonClick = () => {
    elementClicked();
    setIsExpanded(!isExpanded);
  };

  const ctaLink = () => (
    <CTALink
      html_id="subject-product-link"
      elementType={ElementType.Entry}
      location={location}
      variant={webElement.variant}
      url={ctaUrl as string}
      label={ctaLabel as string}
      radius="none"
      size="lg"
    />
  );

  const isEmptyList = displayedProducts.length === 0;
  const shouldShowTitle = title || isUsingFallback;
  const shouldShowCTA =
    !showAllProducts && (filteredProducts.length ?? 0) > initialCardsLength;
  const shouldShowDisclaimer = showDisclaimer && isUsingFallback;

  return (
    <>
      {shouldShowDisclaimer && <Message variant="no-degree" copy={copy} />}
      {isEmptyList ? (
        <> </>
      ) : (
        <>
          {children && children}
          <a className="subnav-item" id={titleId}></a>
          <div className={cn("md:mb-12", cardWrapperClassName)}>
            {shouldShowTitle && (
              <h4 className="-mb-1">
                <span className="inline-flex gap-2 items-center justify-center break-words whitespace-normal">
                  {isUsingFallback
                    ? copy
                      ? `Explore related ${copy} programs and courses`
                      : `Explore related programs and courses`
                    : title}
                </span>
              </h4>
            )}
            <ProductCardList
              products={displayedProducts}
              shouldScroll={false}
              location={location}
              cardFormatSubType={cardFormatSubtype}
              className={className}
              skipViewEvents={skipEvents}
              isMultiCourse={isMultiCourse}
              tagular_variant={tagular_variant}
            />

            <div className="flex justify-center mt-6 md:mt-5">
              {shouldShowCTA ? (
                isExpanded && ctaUrl && ctaLabel ? (
                  ctaLink()
                ) : (
                  <span
                    role="button"
                    ref={showMoreRef}
                    className="underline cursor-pointer"
                    onClick={handleShowButtonClick}
                  >
                    {isExpanded ? contractLabel : expandLabel}
                  </span>
                )
              ) : (
                ctaUrl && ctaLabel && ctaLink()
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
